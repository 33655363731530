import { BsArrowRight } from 'react-icons/bs';
import latestData from '../../data/latestData';
import React from 'react';
import { Link } from 'gatsby';

const LatestPost = ({ allPost }) => {
  return (
    <section className='latest__post__features section__space bg__img'>
      <div className='container'>
        <div className='latest__post__features__area wow fadeInUp'>
          <div className='title__with__cta'>
            <div className='row d-flex align-items-center'>
              <div className='col-lg-8'>
                <h2>Latest Posts</h2>
              </div>
              <div className='col-lg-4'>
                <div className='text-start text-lg-end'>
                  <Link
                    to='/blog'
                    className='button button--secondary button--effect'
                  >
                    Browse All Posts
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className='row altr'>
            {allPost.edges.slice(3, 6).map((item) => {
              const {
                id,
                title,
                img = item.node.feature_image,
                description,
                slug,
                excerpt,
              } = item.node;
              return (
                <div key={id} className='col-md-6 col-xl-4'>
                  <div className='featured__large__post'>
                    <Link to={'/' + slug} className='thumbnail'>
                      <img src={img} alt={title} />
                    </Link>
                    <div className='blog__content'>
                      <h5>
                        <Link to={'/' + slug}>{title}</Link>
                      </h5>
                      <p>{excerpt && excerpt.slice(0, 63)}</p>
                      <Link to={'/' + slug}>
                        Read More <BsArrowRight />
                      </Link>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
};

export default LatestPost;
