import Social from '../social/Social';
import avatar from '/public/images/blog/avatar.png';
import calendar from '/public/images/blog/calendar.png';
import singlePoster from '/public/images/blog/single-poster.png';
import watch from '/public/images/blog/watch.png';
import React from 'react';

const Header = ({ post }) => {
  return (
    <>
      <div
        className='blog__single__banner clear__top'
        style={{
          backgroundImage: `url(${post.feature_image || singlePoster})`,
          filter: 'blur(20px)',
        }}
        data-background={post.feature_image}
      ></div>
      <section className='blog__single__post'>
        <div className='container'>
          <div className='blog__single__post__content  mb-5'>
            <div className='blog__details__head'>
              <div className='blog__item__info'>
                <div className='post__date'>
                  <div className='dat'>
                    <img src={calendar} alt='Post Date' />
                    <p>{post.published_at_pretty}</p>
                  </div>
                </div>
                <div className='post__read__time'>
                  <div className='dat'>
                    <img src={watch} alt='Post Read Time' />
                    <p>{post.reading_time} Min Read</p>
                  </div>
                </div>
              </div>
              <div className='blog__social'>
                <div className='social text-start text-lg-end'>
                  {/* <Social /> */}
                </div>
              </div>
            </div>
            <h2>{post.title}</h2>

            <div className='poster '>
              <img src={post.feature_image || singlePoster} alt='Poster' />
            </div>

            <section
              className='content-body load-external-scripts mt-5'
              dangerouslySetInnerHTML={{ __html: post.html }}
            />
          </div>
          {/* <div className='section__space'>
            <div className='blog__post__author bg__img'>
              <img src={avatar} alt='Avatar' />
              <div>
                <h4>Fannie Simon</h4>
                <p className='cta__space'>Joined Spoment on Dec 17, 2021</p>
                <div className='social text-start'>
                  <Social />
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </section>
    </>
  );
};

export default Header;
