import * as React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';

import { Layout } from '../components/common';
import { MetaData } from '../components/common/meta';
import Banner from '../../components/common/Banner';
import Breadcrumb from '../../components/common/Breadcrumb';
// import baner__thumb from "/public/images/banner/key-illustration.png";
import baner__thumb from '../../public/images/banner/key-illustration.png';
import Header from '../../components/singleBlog/Header';
import LatestPost from '../../components/singleBlog/LatestPost';

/**
 * Single post view (/:slug)
 *
 * This file renders a single post and loads all the content.
 *
 */
const Post = ({ data, location }) => {
  const post = data.ghostPost;
  const allPost = data.allGhostPost;

  return (
    <>
      <MetaData data={data} location={location} type='article' />
      <Helmet>
        <style type='text/css'>{`${post.codeinjection_styles}`}</style>
      </Helmet>
      <Layout>
        <Header post={post} />
        <LatestPost allPost={allPost} />
      </Layout>
    </>
  );
};

Post.propTypes = {
  data: PropTypes.shape({
    ghostPost: PropTypes.shape({
      codeinjection_styles: PropTypes.object,
      title: PropTypes.string.isRequired,
      html: PropTypes.string.isRequired,
      feature_image: PropTypes.string,
    }).isRequired,
  }).isRequired,
  location: PropTypes.object.isRequired,
};

export default Post;

export const postQuery = graphql`
  query ($slug: String!) {
    ghostPost(slug: { eq: $slug }) {
      ...GhostPostFields
    }
    allGhostPost {
      edges {
        node {
          url
          title
          feature_image
          id
          slug
          excerpt
        }
      }
    }
  }
`;
